import { mapHelper } from 'common/utils'

// 表单状态
const status = [
  {
    text: '未发布',
    value: 0
  },
  {
    text: '已发布',
    value: 1
  },
  {
    text: '已过期',
    value: 2
  },
  {
    text: '已停用',
    value: 3
  }
]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)

// 表单类型
const formType = [
  {
    text: '物业表单',
    value: 1
  },
  {
    text: '招商表单',
    value: 2
  },
  {
    text: '活动投票',
    value: 3
  },
  {
    text: '问卷调查',
    value: 4
  },
  {
    text: '报名登记',
    value: 5
  },
  {
    text: '在线测评',
    value: 6
  },
  {
    text: '订单服务',
    value: 7
  }
]

const {
  map: formTypeMap,
  setOps: setFormTypeOps
} = mapHelper.setMap(formType)

// 发布端
const publishType = [
  {
    text: 'PC端',
    value: 1
  },
  {
    text: '移动端',
    value: 2
  },
  {
    text: '通用',
    value: 3
  }
]

const {
  map: publishTypeMap,
  setOps: setPublishTypeOps
} = mapHelper.setMap(publishType)

// 项目状态
const communityStatus = [
  {
    text: '正常',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  }
]

const {
  map: communityStatusMap,
  setOps: setCommunityStatusOps
} = mapHelper.setMap(communityStatus)
//状态
const openStatus=[
  {
    text: '开启',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  }
]
const {
  map: openStatusMap,
  setOps: setOpenStatusOps
} = mapHelper.setMap(openStatus)
// 审批设置
const approval = [
  {
    text: '简单审批',
    label: '简单审批',
    value: '1',
    disabled: false
  },
  {
    text: '流程审批',
    label: '流程审批',
    value: '2',
    disabled: false
  },
  {
    text: '不启用',
    label: '不启用',
    value: '0',
    disabled: false
  }
]

const {
  map: approvalMap,
  setOps: setApprovalOps
} = mapHelper.setMap(approval)

// 填写次数
const fillType = [
  {
    text: '单次填写',
    label: '单次填写',
    value: '0'
  },
  {
    text: '多次填写',
    label: '多次填写',
    value: '1'
  },
  {
    text: '组织单次填写',
    label: '组织单次填写',
    value: '2'
  }
]

const {
  map: fillTypeMap,
  setOps: setFillTypeOps
} = mapHelper.setMap(fillType)

// 表单截止
const validityType = [
  {
    text: '长期有效',
    label: '长期有效',
    value: '1'
  },
  {
    text: '暂时生效',
    label: '暂时生效',
    value: '0'
  }
]

const {
  map: validityTypeMap,
  setOps: setValidityTypeOps
} = mapHelper.setMap(validityType)

// 是否声明
const isAgreement = [
  {
    text: '不启用声明',
    label: '不启用声明',
    value: '0',
    disabled: false
  },
  {
    text: '初次打开表单时',
    label: '初次打开表单时',
    value: '1',
    disabled: false
  },
  {
    text: '每次打开表单时',
    label: '每次打开表单时',
    value: '2',
    disabled: false
  },
  {
    text: '每次驳回时（仅限设置审批时有效）',
    label: '每次驳回时（仅限设置审批时有效）',
    value: '3',
    disabled: false
  }
]

const {
  map: isAgreementMap,
  setOps: setIsAgreementOps
} = mapHelper.setMap(isAgreement)

// 表单记录状态
const recordStatus = [
  {
    text: '审批中',
    value: '0'
  },
  {
    text: '已通过',
    value: '1'
  },
  {
    text: '已拒绝',
    value: '2'
  },
  {
    text: '已驳回',
    value: '3'
  }
]

const {
  map: recordStatusMap,
  setOps: setRecordStatusOps
} = mapHelper.setMap(recordStatus)

// 审批结果
const approveStatus = [
  {
    text: '通过',
    value: 1
  },
  {
    text: '拒绝',
    value: 2
  },
  {
    text: '驳回',
    value: 3
  }
]

const {
  map: approveStatusMap,
  setOps: setApproveStatusOps
} = mapHelper.setMap(approveStatus)

// 是否编辑完成页
const isResultPage = [
  {
    text: '是',
    label: '是',
    value: '1'
  },
  {
    text: '否',
    label: '否',
    value: '0'
  }
]

const {
  map: isResultPageMap,
  setOps: setIsResultPageOps
} = mapHelper.setMap(isResultPage)

// 填写端口
const formResource = [
  {
    text: '用户端',
    value: 1
  },
  {
    text: 'PC端',
    value: 2
  },
  {
    text: '物管端',
    value: 3
  },
  {
    text: '微信端',
    value: 4
  },
  {
    text: 'QQ端',
    value: 5
  }
]

const {
  map: formResourceMap,
  setOps: setFormResourceOps
} = mapHelper.setMap(formResource)

export {
  openStatus,
  setOpenStatusOps,
  statusMap,
  setStatusOps,
  formTypeMap,
  setFormTypeOps,
  publishTypeMap,
  setPublishTypeOps,
  communityStatusMap,
  setCommunityStatusOps,
  approvalMap,
  setApprovalOps,
  fillTypeMap,
  setFillTypeOps,
  validityTypeMap,
  setValidityTypeOps,
  isAgreementMap,
  setIsAgreementOps,
  recordStatusMap,
  setRecordStatusOps,
  approveStatusMap,
  setApproveStatusOps,
  isResultPageMap,
  setIsResultPageOps,
  formResourceMap,
  setFormResourceOps
}
