<template>
  <div class="MenuList">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create" />
      </template>
      <template #searchSlot>
        <v-select2 label="项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="update(scope.row)" />
      </template>
    </list>
  </div>
</template>

<script>
import { communityParams } from 'common/select2Params'
import { queryListURL} from './api'
import { openStatus } from './map'

export default {
  name: 'configureChannelList',
  components: {
    
  },
  data () {
    return {
      communityParams,
      searchUrl: queryListURL,
      searchParams: {
        communityId: ''
      },
      headers: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'channelCode',
          label: '渠道编码'
        },
        {
          prop: 'bankNum',
          label: '行所号'
        },
        {
          prop: 'updateTime',
          label: '修改时间'
        },
        {
          prop: 'openStatus',
          label: '状态',
          formatter: row => openStatus[row.openStatus]
        }
      ]
    }
  },
  created () {
    
  },
  computed: {
    
  },
  methods: {
  
    create () {
      this.$router.push({
        name: 'configureChannelEdit'
      })
    },
    update (row) {
      this.$router.push({
        name: 'configureChannelEdit',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
